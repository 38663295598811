<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import SelectGroup from "@/components/forms/select-group";
import InputGroup from "@/components/forms/input-group";
import SaveButton from "@/components/buttons/save-button";
// import StatusGroup from "@/components/forms/status-group";
// import router from "../../../router";

export default {
	components: {
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		// ListingTable,
		InputGroup,
		SelectGroup,
		SaveButton,
		// StatusGroup
	},
	name: "Add_Member",
	watch: {
	},
	data() {
		const id = this.$route.params.id;
		const modeName = id === 'add' ? 'Add' : 'Edit';
		return {
			id: id,
			title: `Member ${modeName}`,
			modeName,
			items: [
				{
					text: "Member"
				},
				{
					text: modeName,
					active: true,
				},
			],
			formData: {
				email: "",
				// password: "",
				// password_confirmation: "",
				status: "",
				name: "",
				ic: "",
				phone: "",
				company_name: "",
				company_reg: "",
				company_address: "",
			},

			member_id: '',//id for redirection
			errors: {},

			fields: [
				{ "name": "name", "type": "text", 'label': "Name", "id": "name" },
				{ "name": "ic", "type": "text", 'label': "NRIC/Passport", "id": "ic" },
				{ "name": "phone", "type": "number", 'label': "Phone", "id": "phone" },
			],
			statusOptions: [
				{ value: 1, name: "Active" },
				{ value: 0, name: "Inactive" },
			],
		};
	},
	props: {},
	async created() {
		const fetchDetails = await axios.get(
			`${process.env.VUE_APP_APIURL}/member/${this.id}`,
			{
				headers: authHeader(),
			}
		);

		if (fetchDetails && fetchDetails.data["code"] === "success") {
			let { member } = fetchDetails.data.data;
			this.email = member['email'];
			Object.keys(this.formData).forEach(element => {
				if (member[element] !== '') {
					this.formData[element] = member[element];
				}
			});
			this.status_key += 1;
			this.status_value = member.status;
		}
	},
	methods: {
		async submitForm() {
			this.errors = {};
			let result;
			if (this.modeName === 'Add') {
				result = await axios.post(`${process.env.VUE_APP_APIURL}/member`, this.formData, {
					headers: authHeader(),
				}).catch(function (error) {
					alert("Error in adding member:" + error.message);
				});

				if (result.data.code === 'success') {
					this.member_id = result.data.data.member.id;
					this.$refs.messageModal.showModal('Your record has been updated successfully');
				} else if (result.data.code === 'invalid_field') {
					this.errors = result.data.errors;
				}
			} else {
				result = await axios.put(
					`${process.env.VUE_APP_APIURL}/member/member-info/${this.id}`,
					this.formData,
					{
						headers: authHeader(),
					}
				);

				if (result.data.code === "success") {
					this.member_id = this.id;
					this.$refs.messageModal.showModal("Your record has been updated successfully");
					this.pageRedirection = true;
				} else if (result.data.code === "invalid_field") {
					this.errors = result.data.errors;
				} else {
					this.$refs.messageModal.showModal("Internal Errors");
				}
			}
		},
		// redirectPage() {
		// 	router.push({ 'name': "Member View", params: { id: this.member_id } })
		// }
	},
};
</script>

<style lang="scss" scoped></style>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card" style="border-radius: 15px;">
					<div class="card-body">
						<div class="row mt-2">
							<h4 class="col-12 subTitle-font">
								Account Info
							</h4>
							<div class="col-12 col-md-4 mb-2">
								<!-- {{ modeName }} -->
								<InputGroup type="email" id="email" displayName="Email" v-model="formData.email"
									:error="errors.email" />
							</div>

							<h4 class="col-12 subTitle-font mt-3">
								Member Info
							</h4>

							<template v-for="field in fields">
								<div class="col-12 col-md-4 mb-2" :key="field.id" v-if="field.name != 'phone'">
									<InputGroup :type="field.type" :id="field.id" :displayName="field.label"
										v-model="formData[field.name]" :error="errors[field.name]" :ref="field.name" />
								</div>
								<div class="col-12 col-md-4 mb-2" :key="'phone-' + field.id" v-else>
									<div class="form-group">
										<!-- <div>
                                <label>Phone No.</label>
                                <div class="d-flex">
                                  <input type="text" class="form-control w-25 mr-2 text-center" disabled value="+60">
                                  <input type="text" class="form-control w-75" placeholder="Phone No." v-model="formData.phone">
                                </div>
                              </div> -->
										<label for="size" class="mb-0">Phone No.</label>
										<div class="input-group mb-0">
											<input id="phone" name="phone" class="form-control" placeholder="Phone"
												v-model="formData.phone" />
										</div>
										<div class="alert alert-danger mt-3" role="alert"
											v-if="errors.hasOwnProperty('phone')">
											{{ errors.phone[0] }}
										</div>
									</div>
								</div>
							</template>

							<div class="col-12 col-md-4 mb-2">
								<!-- <StatusGroup v-model="formData.status" :error="errors.status" :statusVal="formData.status"
									:allSelection="false" /> -->
								<SelectGroup id="status" displayName="Status" v-model="formData.status" :error="errors.status" :options="statusOptions" />
							</div>

							<h4 class="col-12 subTitle-font mt-3">
								Company Information (Optional)
							</h4>

							<div class="col-12 col-md-4 mb-2">
								<InputGroup type="text" id="compName" displayName="Company Name (Optional)"
									v-model="formData.company_name" :error="errors.company_name" />
							</div>

							<div class="col-12 col-md-4 mb-2">
								<InputGroup type="text" id="compReg" displayName="Company Registration No. (Optional)"
									v-model="formData.company_reg" :error="errors.company_reg" />
							</div>

							<div class="col-md-8 col-12 mb-2">
								<label class="mb-0">Company Address (Optional)</label>
								<textarea class="form-control" id="compAddress" placeholder="Company Address (optional)"
									v-model="formData.company_address"></textarea>
							</div>
							<div class="alert alert-danger mt-3 col-md-8 col-12 " role="alert"
								v-if="errors.hasOwnProperty('company_address')">
								{{ errors.company_address[0] }}
							</div>
							<div class="col-12 mt-4">
								<SaveButton @click="submitForm" />
								<RouterLink class="btn btn-md btn-filter mb-2 ml-2"
									:to="{ name: 'Member View', params: { id } }">
									Cancel
								</RouterLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>